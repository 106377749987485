// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar/navBar"

function YourDetailsPage() {
    const account = {
        firstname: "Nasir",
        lastname: "Bawa",
        membership: true,
        membership_details: {
            wsg_type: "single",
            bca_type: "caver",
            bca_number: "11156"
        },
    }
    let fee = 0
    if (!account.membership) {
        fee = 30 // get this from cms for membership type 
    }

    return (
        <Layout>
          <Seo title="About Us" />
          <NavBar />
          <h1>Hi {account.firstname}</h1>
          <p>
            Your membership is {account.membership ? "active" : "inactive"} for this year. 
          </p>
          {
          !account.membership && 
            <p>
                If you would like to rejoin as a member please send £{fee} to 
                Westminster Speleological Group
                20-22-22
                03691765
                and email treasurer@wsg.org.uk
            </p>
          }
          <div>
            <button>Edit details</button>
            <button>Add a trip report</button>
          </div>
            
        </Layout>
      ) 
}


export default YourDetailsPage
